import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mapTo, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import Appointment from '../../../../Domain/Appointments/Appointment';
import GetCustomerAppointmentsUseCase from '../../../../Domain/Appointments/GetCustomersAppointmentsUseCase';
import CancelBookingUseCase from '../../../../Domain/Bookings/CancelBookingUseCase';
import { DeleteCustomerUseCase } from '../../../../Domain/Customers/DeleteCustomerUseCase';
import EditCustomerUseCase from '../../../../Domain/Customers/EditCustomerUseCase';
import GetCustomerUseCase from '../../../../Domain/Customers/GetCustomerUseCase';
import Customer from '../../../../Domain/Entities/Customer';
import { getPackageState } from '../../../../Domain/Entities/Package';
import DeletePackageUseCase from '../../../../Domain/Packages/DeletePackageUseCase';
import ResetUserPasswordUseCase from '../../../../Domain/UserAuth/ResetUserPasswordUseCase';
import Logger from '../../../../Logger/Logger';
import DateTimeProvider from '../../../../Utils/DateTimeProvider';
import { format } from '../../../../Utils/DateUtils';
import { checkExists } from '../../../../Utils/Preconditions';
import { Failure, Loading, Success } from '../../../../Utils/Result';
import strings from '../../../Utils/LocalizedStrings';
import CustomerDetailView from './CustomerDetailView';
import UiBasicAppointment from './UiBasicAppointment';
import UiCustomer from './UiCustomer';
import UiEditingCustomer from './UiEditingCustomer';
import { mapPackageToUiSimplePackage } from './UiSimplePackage';

export default class CustomerDetailPresenter {

  private readonly subscription = new Subscription();

  private readonly customer: Observable<Customer>
  private readonly appointments: Observable<Appointment[]>
  private readonly refreshAppointments = new Subject();

  private readonly editingCustomer = new BehaviorSubject<UiEditingCustomer | null>(null);
  private isEditingEnabled: boolean = false;

  private view?: CustomerDetailView;

  constructor(
    customerId: string,
    getCustomer: GetCustomerUseCase,
    getCustomerAppointments: GetCustomerAppointmentsUseCase,
    private readonly deleteCustomer: DeleteCustomerUseCase,
    private readonly editCustomerUseCaseProvider: () => EditCustomerUseCase,
    private readonly cancelBookingUseCaseProvider: () => CancelBookingUseCase,
    private readonly resetUserPasswordUseCaseProvider: () => ResetUserPasswordUseCase,
    private readonly deletePackageUseCaseProvider: () => DeletePackageUseCase,
    private readonly dateTimeProvider: DateTimeProvider
  ) {
    this.customer = getCustomer.execute(customerId).pipe(shareReplay(1));
    this.appointments = this.refreshAppointments
      .pipe(startWith({}), switchMap(() => getCustomerAppointments.execute(customerId)))
      .pipe(shareReplay(1));
  }

  attachView = (v: CustomerDetailView) => {
    this.view = v;

    this.subscription.add(
      this.customer
        .pipe(map(this.toUiCustomer))
        .subscribe(
          customer => this.view?.showCustomer(customer),
          error => {
            this.view?.showError(strings.admin.clients.detail.errorGettingClientInfo);
            Logger.e(error);
          }
        )
    );

    this.subscription.add(
      this.appointments
        .pipe(
          map(apps => apps.map(this.toUiBasicAppointment)),
          map(appointments => Success(appointments)),
          startWith(Loading<UiBasicAppointment[]>()),
          catchError(error => of(Failure<UiBasicAppointment[]>(error)))
        )
        .subscribe(
          result => {
            if (result.isLoading) {
              this.view?.showAppointmentsLoading();
            } else {
              this.view?.hideAppointmentsLoading();
              result.fold(
                appointments => this.view?.showAppointments(appointments),
                error => {
                  this.view?.showError(strings.admin.clients.detail.errorGettingAppointments);
                  Logger.e(error)
                }
              )
            }
          }
        )
    );

    this.subscription.add(
      this.editingCustomer
        .subscribe(
          state => {
            if (state) {
              this.view?.showCustomerEditData({ ...state });
            } else {
              this.view?.showCustomerEditData(null);
            }
          }
        )
    )
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  resetPassword = () => {
    this.subscription.add(
      this.customer
        .pipe(
          take(1),
          switchMap(customer => this.resetUserPasswordUseCaseProvider().execute(customer.identifier)),
          mapTo(Success({})),
          startWith(Loading()),
          catchError(error => of(Failure(error)))
        )
        .subscribe(
          result => {
            if (result.isLoading) {
              this.view?.showResetPasswordLoading();
            } else {
              this.view?.hideResetPasswordLoading();
              result.fold(() => { }, Logger.e);
            }
          },
          Logger.e
        )
    );
  }

  /**
   * Called when the user toggled the editing state of the customer.
   */
  editCustomerToggled = () => {
    this.isEditingEnabled = !this.isEditingEnabled;
    this.updateViewEditState();

    if (this.isEditingEnabled) {
      // If the customer editing is enabled, seed the `editingCustomer` state 
      // with the current customer info.
      this.subscription.add(
        this.customer.pipe(take(1)).subscribe(customer =>
          this.editingCustomer.next({
            name: customer.name,
            surname: customer.surname,
            email: customer.email,
            phoneNumber: customer.phone,
          })
        )
      );
    } else {
      // If the user is no more editing the customer info, save the changes
      // that have been made.
      this.saveCustomerChangesIfNeeded();
    }
  };

  /**
   * Closes the editing session restoring the default customer data into the UI.
   */
  closeCustomerEditing = () => {
    this.isEditingEnabled = false;
    this.editingCustomer.next(null);
    this.updateViewEditState();
  }

  setCustomerName = (name: string) => {
    this.updateEditingState({ name: name });
  }

  setCustomerSurname = (surname: string) => {
    this.updateEditingState({ surname: surname });
  }

  setCustomerEmail = (email: string) => {
    this.updateEditingState({ email: email });
  }

  setCustomerPhoneNumber = (phoneNumber: string) => {
    this.updateEditingState({ phoneNumber: phoneNumber });
  }

  setCustomerEditing = (customer: UiEditingCustomer) => {
    this.editingCustomer.next(customer);
  }

  doDeleteCustomer = () => {
    this.subscription.add(
      this.customer
        .pipe(
          take(1),
          switchMap(customer => this.deleteCustomer(customer.identifier)),
          mapTo(Success({})),
          startWith(Loading()),
          catchError(error => of(Failure(error)))
        )
        .subscribe(
          result => {
            if (result.isLoading) {
              this.view?.showDeleteCustomerLoading();
            } else {
              this.view?.hideDeleteCustomerLoading();
              result.fold(
                () => this.view?.navigateToCustomersList(),
                error => Logger.e(error)
              );
            }
          },
          Logger.e
        )
    );
  }

  cancelAppointment = (appointmentId: string) => {
    this.subscription.add(
      this.appointments
        .pipe(take(1))
        .subscribe(
          (appointments: Appointment[]) => {
            const appointment = appointments.find(app => app.identifier === appointmentId);
            if (appointment) {
              const day = format(appointment.startDate, "EEEE dd MMMM yyyy");
              const time = format(appointment.startDate, "HH:mm");
              this.view?.showAppointmentCancellationDialog(
                appointment.identifier,
                strings.formatString(
                  strings.admin.clients.detail.cancelAppointmentConfirm,
                  day, time
                ).toString()
              );
            }
          },
          Logger.e
        )
    );
  }

  confirmAppointmentCancellation = (appointmentId: string | undefined | null) => {
    if (!appointmentId) return;
    this.subscription.add(
      this.appointments
        .pipe(
          take(1),
          switchMap((appointments: Appointment[]) => {
            const appointment = appointments.find(app => app.identifier === appointmentId);
            return appointment
              ? of(appointment)
              : throwError(new Error(`No such appointment ${appointmentId}`));
          }),
          switchMap(appointment =>
            this.customer
              .pipe(
                take(1),
                switchMap(customer => {
                  const booking = appointment.bookings.find(booking =>
                    booking.userInfo.identifier === customer.identifier
                  );
                  return booking
                    ? this.cancelBookingUseCaseProvider().execute(booking.identifier)
                    : throwError(new Error(`No such booking for customer ${customer.identifier}`));
                })
              )
          )
        )
        .subscribe(
          () => {},
          Logger.e,
          () => this.refreshAppointments.next({})
        )
    );
  }

  deletePackage = (packageId: string) => {
    this.subscription.add(
      this.customer
        .pipe(
          take(1),
          switchMap(customer => this.deletePackageUseCaseProvider().execute(packageId, customer.identifier))
        )
        .subscribe((packageId) => Logger.d(`Package ${packageId} deleted`), Logger.e)
    );
  }

  // Private methods

  private updateViewEditState = () => {
    if (this.isEditingEnabled) {
      this.view?.enableCustomerEditForm();
    } else {
      this.view?.disableCustomerEditForm();
    }
  }

  private saveCustomerChangesIfNeeded = () => {
    this.subscription.add(
      combineLatest([
        this.editingCustomer.pipe(map(c => checkExists(c))),
        this.customer
      ])
        .pipe(
          take(1),
          filter(([state, customer]) =>
            // Filter the editing state proceeding with the changes saving only if
            // some data have been modified.
            state.name !== customer.name
            || state.surname !== customer.surname
            || state.email !== customer.email
            || state.phoneNumber !== customer.phone
          ),
          switchMap(([state, customer]) =>
            this.editCustomerUseCaseProvider().execute(
              customer.identifier,
              state.name,
              state.surname,
              state.email,
              state.phoneNumber
            ).pipe(
              mapTo(Success({})),
              startWith(Loading()),
              catchError(error => of(Failure(error))),
            )
          )
        )
        .subscribe(
          result => {
            if (result.isLoading) {
              this.view?.showCustomerEditLoading();
            } else {
              this.view?.hideCustomerEditLoading();
              result.fold(
                () => { /*edited*/ },
                error => {
                  Logger.e(error);
                  this.view?.showError(strings.admin.clients.detail.errorEditingClient);
                }
              );
              // At the end, reset the editing state.
              this.editingCustomer.next(null);
            }
          },
          error => {
            Logger.e(error);
            this.view?.showError(strings.admin.clients.detail.errorEditingClient);
          }
        )
    );
  }

  private updateEditingState = (newValue: {}) => {
    const current = this.editingCustomer.value;
    const newState: UiEditingCustomer = {
      name: current?.name ?? "",
      surname: current?.surname ?? "",
      email: current?.email ?? "",
      phoneNumber: current?.phoneNumber ?? null,
    };
    this.editingCustomer.next(
      Object.assign(newState, newValue)
    );
  }

  private toUiCustomer: (customer: Customer) => UiCustomer = (customer) => {
    const formatDate: (date: Date) => string = date => format(date, "dd/MM/yyyy");
    const currentDate = new Date(this.dateTimeProvider.currentTimeMillis());
    return {
      identifier: customer.identifier,
      name: customer.name,
      surname: customer.surname,
      email: customer.email,
      phoneNumber: customer.phone || "",
      packages: customer.packages
        // Sort packages with latest start date first.
        .sort((p1, p2) => p2.startDate.getTime() - p1.startDate.getTime())
        // Limit number of displayed packages to 3.
        .slice(0, Math.min(3, customer.packages.length))
        .map(pack => ({
          ...mapPackageToUiSimplePackage(pack, getPackageState(pack, currentDate), formatDate),
          tokenCount: pack.leftTokenCount,
        }))
    }
  }

  private toUiBasicAppointment: (appointment: Appointment) => UiBasicAppointment = (appointment) => {
    return {
      identifier: appointment.identifier,
      displayableDate: format(appointment.startDate, "dd/MM/yyyy"),
      displayableTimeStart: format(appointment.startDate, "HH:mm"),
      displayableTimeEnd: format(appointment.endDate, "HH:mm"),
      participantsCount: appointment.bookings.length.toString(),
      isCancelable: true,
    }
  }
};

