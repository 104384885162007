import { Box, Button, CircularProgress, createStyles, Grid, Hidden, IconButton, makeStyles, TextField } from '@material-ui/core';
import { CancelOutlined, Check, Edit } from '@material-ui/icons';
import { useState } from 'react';
import { packageStatusExpired } from '../../../../Domain/Entities/Package';
import { perUsagePackage } from '../../../../Domain/Entities/PackageTypes';
import AppointmentCard from '../../../Commons/AppointmentCard';
import KeyValueDialog from '../../../Commons/KeyValueDialog';
import PackageCard from '../../../Commons/PackageCard';
import strings from '../../../Utils/LocalizedStrings';
import { getPackageStatusIcon } from '../../Packages/PackagesIcons';
import { getPackageColor } from '../../Packages/PackagesMappers';
import UiBasicAppointment from './UiBasicAppointment';
import UiPackage from './UiPackage';

interface CustomerDetailViewProps {
  readonly customerName: string;
  readonly customerSurname: string;
  readonly customerEmail: string;
  readonly customerPhoneNumber: string;
  readonly packages: UiPackage[];
  readonly appointments: UiBasicAppointment[];
  readonly isLoadingAppointments: boolean;
  readonly isEditingEnabled: boolean;
  readonly isSavingEditedChanges: boolean;
  readonly onNameChanged: (name: string) => void;
  readonly onSurnameChanged: (name: string) => void;
  readonly onEmailChanged: (name: string) => void;
  readonly onPhoneNumberChanged: (name: string) => void;
  readonly onAssignPackageClicked: () => void;
  readonly onResetPasswordClicked: () => void;
  readonly onEditCustomerClicked: () => void;
  readonly onCloseEditingCustomerClicked: () => void;
  readonly onDeleteCustomerClicked: () => void;
  readonly onCancelAppointmentClicked: (appointmentId: string) => void;
  readonly onEditPackageClicked: (packageId: string) => void;
  readonly onDeletePackageClicked: (packageId: string) => void;
}

const useStyles = makeStyles((theme) => createStyles({
  titleContainer: {
    marginBottom: theme.spacing(2)
  },
  title: {
    ...theme.typography.h4,
    ...theme.palette.text
  },
  sectionTitleContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  sectionTitle: {
    ...theme.typography.h6,
    ...theme.palette.text
  },
  gridItem: {
    display: "flex",
    flexGrow: 1,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  cardsGrid: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  cardItem: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  appointmentGridItem: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  manageSectionItem: {
    display: "flex",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  editedChangesProgress: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(),
  }
}));

export interface CustomerFormData {
  readonly name: string;
  readonly surname: string;
  readonly email: string;
  readonly phoneNumber: string;
}

const CustomerDetailScreen = (props: CustomerDetailViewProps) => {
  // const { } = props;

  const classes = useStyles();
  const [packageToDelete, setPackageToDelete] = useState<UiPackage | null>(null);

  return (
    <Box display="flex" >

      <Hidden smDown>
        <Box m={4}></Box>
      </Hidden>

      <Grid container>

        <Grid item xs={12} className={classes.titleContainer}>
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1} alignSelf="center"><span className={classes.title}>{strings.admin.clients.detail.title}</span></Box>
            {
              props.isEditingEnabled
                ? <IconButton aria-label="edit-user-info" onClick={() => props.onCloseEditingCustomerClicked()}>
                  <CancelOutlined />
                </IconButton>
                : null
            }
            {
              props.isSavingEditedChanges
                ? <CircularProgress className={classes.editedChangesProgress} />
                : <IconButton aria-label="edit-user-info" onClick={() => props.onEditCustomerClicked()}>
                  {props.isEditingEnabled ? <Check /> : <Edit />}
                </IconButton>
            }
          </Box>
        </Grid>

        <Grid item lg={6} xs={12}>
          <TextField
            className={classes.gridItem}
            label={strings.admin.clients.detail.name}
            variant="outlined"
            value={props.customerName}
            onChange={event => props.onNameChanged(event?.target?.value ?? "")}
            key="name"
            disabled={!props.isEditingEnabled} />
        </Grid>

        <Grid item lg={6} xs={12}>
          <TextField
            className={classes.gridItem}
            label={strings.admin.clients.detail.surname}
            variant="outlined"
            value={props.customerSurname}
            onChange={event => props.onSurnameChanged(event?.target?.value ?? "")}
            key="surname"
            disabled={!props.isEditingEnabled} />
        </Grid>

        <Grid item lg={6} xs={12}>
          <TextField
            className={classes.gridItem}
            label={strings.admin.clients.detail.email}
            variant="outlined"
            value={props.customerEmail}
            onChange={event => props.onEmailChanged(event?.target?.value ?? "")}
            type="email"
            key="email"
            disabled={!props.isEditingEnabled} />
        </Grid>

        <Hidden mdDown>
          <Grid item lg={6} xs={12}></Grid>
        </Hidden>

        <Grid item lg={6} xs={12}>
          <TextField
            className={classes.gridItem}
            label={strings.admin.clients.detail.phone}
            variant="outlined"
            value={props.customerPhoneNumber}
            onChange={event => props.onPhoneNumberChanged(event?.target?.value ?? "")}
            type="tel"
            key="phoneNumber"
            disabled={!props.isEditingEnabled} />
        </Grid>

        <Hidden mdDown>
          <Grid item lg={6} xs={12}></Grid>
        </Hidden>

        <Grid item xs={12} className={classes.sectionTitleContainer}>
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1} alignSelf="center">
              <span className={classes.sectionTitle}>
                {strings.admin.clients.detail.packages}
              </span>
            </Box>

            {/* TODO: Packages history */}
            {/* 
            <IconButton aria-label="bundles-history-info">
              <History />
            </IconButton>
             */}

          </Box>
        </Grid>

        <Grid item lg={9} xs={12}>
          {
            props.packages.length === 0
              ? <span>{strings.admin.clients.detail.noAssignedPackages}</span>
              : <Box className={classes.cardsGrid}>
                {props.packages.map(pack =>
                  <PackageCard
                    className={classes.cardItem}
                    key={pack.identifier}
                    title={pack.name}
                    displayableDateFrom={pack.displayableDateFrom}
                    displayableDateTo={pack.displayableDateTo}
                    tokenCount={pack.tokenCount.toString()}
                    weeklyTokenLimit={pack.weeklyTokensLimit}
                    image={pack.packageImageUrl}
                    statusImage={getPackageStatusIcon(pack.status)}
                    statusText={pack.statusText}
                    statusColor={getPackageColor(pack.status)}
                    // 07/03/2025: package editing is always enabled to allow flexibility
                    isEditPackageEnabled={true} 
                    // 07/03/2025: package deletion is enabled only if 
                    //    the package is expired or 
                    //    has no tokens left or 
                    //    is a per-usage package
                    isDeletePackageEnabled={pack.status === packageStatusExpired || pack.tokenCount === 0 || pack.type === perUsagePackage}
                    onEditClicked={() => props.onEditPackageClicked(pack.identifier)}
                    onDeleteClicked={() => setPackageToDelete(pack)} />
                )}
              </Box>
          }
        </Grid>

        <Hidden mdDown>
          <Grid item lg={3} xs={12}></Grid>
        </Hidden>

        <Grid item xs={12} className={classes.sectionTitleContainer}>
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1} alignSelf="center">
              <span className={classes.sectionTitle}>
                {strings.admin.clients.detail.appointments}
              </span>
            </Box>

            {/* TODO: Appointments history */}
            {/* 
            <IconButton aria-label="appointments-info">
              <PlaylistAddCheck />
            </IconButton> 
            */}

          </Box>
        </Grid>

        <Grid item lg={9} xs={12}>
          {
            props.isLoadingAppointments
              ? <CircularProgress />
              : (
                props.appointments.length === 0
                  ? <span>{strings.admin.clients.detail.noAppointments}</span>
                  : <Box className={classes.cardsGrid}>
                    {props.appointments.map(app =>
                      <AppointmentCard
                        key={app.identifier}
                        className={classes.appointmentGridItem}
                        displayableDate={app.displayableDate}
                        displayableTimeStart={app.displayableTimeStart}
                        displayableTimeEnd={app.displayableTimeEnd}
                        participantsCount={app.participantsCount}
                        displayCancelButton
                        onCancelButtonClicked={() => props.onCancelAppointmentClicked(app.identifier)} />
                    )}
                  </Box>
              )
          }
        </Grid>

        <Hidden mdDown>
          <Grid item lg={3} xs={12}></Grid>
        </Hidden>

        <Grid item xs={12} className={classes.sectionTitleContainer}>
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1} alignSelf="center"><label className={classes.sectionTitle}>{strings.admin.clients.detail.manage}</label></Box>
          </Box>
        </Grid>

        <Grid item lg={9} xs={12}>
          <Box className={classes.cardsGrid}>
            <Button
              className={classes.manageSectionItem}
              variant="contained"
              onClick={() => props.onAssignPackageClicked()}
              disableElevation>{strings.admin.clients.detail.assignPackage}</Button>
              
            <Button
              className={classes.manageSectionItem}
              variant="contained"
              onClick={() => props.onResetPasswordClicked()}
              disableElevation>{strings.admin.clients.detail.resetPassword}</Button>

            <Button
              className={classes.manageSectionItem}
              variant="contained"
              color='secondary'
              onClick={props.onDeleteCustomerClicked}
              disableElevation>{strings.admin.clients.detail.deleteCustomer}</Button>

          </Box>
        </Grid>

      </Grid>

      <KeyValueDialog
        title={strings.admin.clients.detail.deletePackageTitle}
        message={strings.admin.clients.detail.deletePackageMessage}
        content={[
          { key: strings.admin.clients.packageAssignment.client, value: `${props.customerName} ${props.customerSurname}` },
          { key: strings.admin.clients.packageAssignment.package, value: packageToDelete?.name || "" },
          { key: strings.admin.clients.packageAssignment.startDate, value: packageToDelete?.displayableDateFrom || "" },
          { key: strings.admin.clients.packageAssignment.endDate, value: packageToDelete?.displayableDateTo || "" },
        ]}
        isVisible={packageToDelete !== null}
        confirmButtonText={strings.admin.clients.detail.deletePackageAction}
        onConfirmButtonClicked={() => {
          packageToDelete && props.onDeletePackageClicked(packageToDelete.identifier);
          setPackageToDelete(null);
        }}
        onCancelButtonClicked={() => setPackageToDelete(null)}
      />
    </Box>
  )
};

export default CustomerDetailScreen;

