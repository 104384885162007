import { Observable } from "rxjs";
import PackagesRepository from "./PackagesRepository";

export default interface DeletePackageUseCase {
    execute: (packageId: string, customerId: string) => Observable<string>
}

export function createDeletePackageUseCase(
    packagesRepository: PackagesRepository
): DeletePackageUseCase {
    return {
        execute(packageId, customerId) {
            return packagesRepository.deletePackage(customerId, packageId);
        }
    }
}