import { Observable } from "rxjs";
import { mapTo, switchMap } from "rxjs/operators";
import CustomersRepository from "./CustomersRepository";

export type DeleteCustomerUseCase = (customerId: string) => Observable<string>

export default function createDeleteCustomerUseCase(
    customersRepo: CustomersRepository
): DeleteCustomerUseCase {
    return (customerId) => customersRepo
        .deleteCustomer(customerId)
        .pipe(
            switchMap(() => customersRepo.deleteRecentSearchedCustomer(customerId)),
            mapTo(customerId)
        );
}