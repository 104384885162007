import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import KeyValuePair from '../../Utils/KeyValuePair';

interface KeyValueDialogProps {
  readonly isVisible: boolean;
  readonly title: string;
  readonly content: KeyValuePair<string, string>[];
  readonly message?: string;
  readonly confirmButtonText?: string;
  readonly onConfirmButtonClicked: () => void;
  readonly onCancelButtonClicked?: () => void;
}

/**
 * A simple dialog that shows a title, a key-value content, and provides 
 * a confirm and a cancel button.
 */
const KeyValueDialog = (props: KeyValueDialogProps) => {
  return (
    <Dialog 
      open={props.isVisible} 
      PaperProps={{ 
        style: { 
          borderRadius: "16px",
          padding: "4px"
        } 
      }}
      >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        {props.message
          ? <p style={{ margin: 0, marginBottom: "1em" }}>{props.message}</p>
          : null
        }
        <Box display="flex" flexDirection="column">
          {props.content.map(entry =>
            <Box display="flex" flexDirection="row" key={entry.key}>
              <Box display="flex"><b>{entry.key}</b></Box>
              <Box display="flex" flexGrow="1" justifyContent="flex-end">&nbsp;{entry.value}</Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onConfirmButtonClicked}>{props.confirmButtonText ?? "OK"}</Button>
        <Button color="default" onClick={() => props.onCancelButtonClicked?.()}>Annulla</Button>
      </DialogActions>
    </Dialog>
  );
}

export default KeyValueDialog;