import firebase from 'firebase/app';
import 'firebase/firestore';
import { BehaviorSubject, combineLatest, defer, from, Observable, of, throwError } from 'rxjs';
import { filter, finalize, map, mergeMap, switchMap, take } from 'rxjs/operators';
import CustomersRepository, { UpdateTokenCountError } from '../Domain/Customers/CustomersRepository';
import Customer from '../Domain/Entities/Customer';
import { roleUser } from '../Domain/Roles';
import UserInfo from '../Domain/UserInfo';
import DateTimeProvider from '../Utils/DateTimeProvider';
import { asObservable } from '../Utils/PromiseUtils';
import { FirestoreDocument, observe, observeCollection } from './FirestoreUtils';
import { mapPackageDtoToPackage, PackageDto } from './PackagesRepoImp';

const recentCustomersStorageKey = "recentCustomers"

/**
 * Model used for persisting the customer searches that have been made.
 */
interface RecentSearchDto {
  /** The ID of the searched customer. */
  readonly customerId: string;
  /** The timestamp of the search. */
  readonly timestamp: number;
}

export type CustomerDto = {
  readonly id: string;
  readonly name: string;
  readonly surname: string;
  readonly email: string;
  readonly phone: string;
}

export default class CustomersRepoImp implements CustomersRepository {

  private readonly localStorageUpdated = new BehaviorSubject(Date.now());

  constructor(
    private readonly firestore: () => firebase.firestore.Firestore,
    private readonly functions: () => firebase.functions.Functions,
    private readonly dateTimeProvider: () => DateTimeProvider,
  ) {
  }

  getCustomer = (customerId: string): Observable<Customer> => {
    return this.getCustomerDoc(customerId).pipe(
      filter(doc => doc.exists),
      switchMap(doc => {
        return combineLatest([
          of(doc).pipe(map(doc => ({ id: doc.id, ...doc.data() } as CustomerDto))),
          observeCollection(doc.ref.collection("packages"))
            .pipe(map(querySnapshot =>
              querySnapshot?.docs?.map(packDoc => ({ id: packDoc.id, ...packDoc.data() } as PackageDto)) ?? []
            ))
        ]);
      }),
      map(([userDoc, userPackagesDocs]) => ({
        identifier: userDoc.id,
        name: userDoc.name,
        surname: userDoc.surname,
        email: userDoc.email,
        phone: userDoc.phone,
        packages: userPackagesDocs.map(mapPackageDtoToPackage)
      }))
    )
  }

  private getCustomerDoc = (customerId: string): Observable<FirestoreDocument> =>
    observe(this.firestore().collection("users").doc(customerId));

  getCustomerInfo: (customerId: string) => Observable<UserInfo | null> = (customerId) =>
    new Observable(emitter => {
      return this.firestore().collection("users")
        .doc(customerId)
        .onSnapshot(
          doc => {
            if (doc.exists) {
              emitter.next({
                identifier: doc.id,
                name: doc.get("name"),
                surname: doc.get("surname")
              });
            } else {
              emitter.next(null);
            }
          },
          error => emitter.error(error)
        )
    })

  getAllCustomersInfo: () => Observable<UserInfo[]> = () =>
    new Observable(emitter => {
      return this.firestore().collection("users")
        .where("role", "==", roleUser)
        .onSnapshot(
          snapshot => emitter.next(
            snapshot.docs.map(doc => ({
              identifier: doc.id,
              name: doc.get("name"),
              surname: doc.get("surname")
            }))
          ),
          error => emitter.error(error)
        )
    })

  saveRecentSearchedCustomer = (customerId: string, limit: number) => defer(() => {
    if (localStorage) {
      const json = localStorage.getItem(recentCustomersStorageKey);
      const recentSearches: RecentSearchDto[] = (json && JSON.parse(json)) || [];
      const alreadySavedCustomerIndex = recentSearches.findIndex(search => search.customerId === customerId);

      const newDto: RecentSearchDto = {
        customerId: customerId,
        timestamp: Date.now().valueOf(),
      };

      let newDataset: RecentSearchDto[];
      if (alreadySavedCustomerIndex >= 0) {
        newDataset = [newDto]
          .concat(recentSearches
            .slice(0, alreadySavedCustomerIndex)
            .concat(recentSearches.slice(alreadySavedCustomerIndex + 1)))
      } else {
        newDataset = [newDto].concat(recentSearches)
      }

      localStorage.setItem(
        recentCustomersStorageKey,
        JSON.stringify(newDataset.slice(0, limit))
      );

      return of<void>();
    } else {
      return throwError("Local storage not present!");
    }
  }).pipe(
    finalize(() => this.localStorageUpdated.next(Date.now()))
  )

  getRecentSearchedCustomers = () => this.localStorageUpdated
    .pipe(
      map(() => {
        if (localStorage) {
          const json = localStorage.getItem(recentCustomersStorageKey);
          const recentSearches: RecentSearchDto[] = (json && JSON.parse(json)) || [];
          const customersIds = recentSearches
            // Filter out results that do not match the expected object schema.
            .filter(search => search.customerId && search.timestamp)
            .sort((lhs, rhs) => rhs.timestamp - lhs.timestamp)
            .map(search => search.customerId);
          return customersIds;
        } else {
          return [];
        }
      }
    )
  )

  deleteRecentSearchedCustomer = (customerId: string) => defer(() => {
    if (localStorage) {
      const json = localStorage.getItem(recentCustomersStorageKey);
      const recentSearches: RecentSearchDto[] = (json && JSON.parse(json)) || [];
      const newDataset = recentSearches.filter(search => search.customerId !== customerId);
      localStorage.setItem(recentCustomersStorageKey, JSON.stringify(newDataset));
    }
    return of(customerId);
  }).pipe(
    finalize(() => this.localStorageUpdated.next(Date.now()))
  )

  createCustomer = (
    name: string,
    surname: string,
    email: string,
    phoneNumber: string | null
  ): Observable<Customer> => defer(() =>
    this.functions().httpsCallable('createCustomer')({
      name: name,
      surname: surname,
      email: email,
      phoneNumber: phoneNumber
    })
  ).pipe(map(({ data }) => ({
    identifier: data.id,
    name: data.name,
    surname: data.surname,
    email: data.email,
    phone: data.phoneNumber,
    packages: []
  })));

  editCustomer = (
    userId: string,
    name: string,
    surname: string,
    email: string,
    phoneNumber: string | null,
  ): Observable<void> => defer(() => 
    this.functions().httpsCallable('editCustomer')({
      userId: userId,
      name: name,
      surname: surname,
      email: email,
      phoneNumber: phoneNumber
    })
  ).pipe(map(() => { }));

  deleteCustomer: (customerId: string) => Observable<string> = (customerId) =>
     of(customerId)
      .pipe(
        switchMap(id => from(this.functions().httpsCallable('deleteCustomer')({ customerId: id }))),
        map(() => customerId)
      );

  updateTokenCount = (customerId: string, packageId: string, updater: (prevCount: number) => number): Observable<void> =>
    observe(
      this.firestore()
        .collection("users")
        .doc(customerId)
        .collection("packages")
        .doc(packageId)
    ).pipe(
      take(1),
      mergeMap((pack): Observable<[string, number]> => {
        const leftTokenCount = pack?.get("leftTokenCount") ?? null;
        if (!pack) {
          return throwError(new UpdateTokenCountError(
            `A package does not exists for customer ${customerId}`
          ));
        } else if (leftTokenCount === null) {
          return throwError(new UpdateTokenCountError(
            `leftTokenCount is ${leftTokenCount}`
          ));
        } else {
          const newCount = updater(leftTokenCount);
          return of([pack.id, newCount]);
        }
      }),
      mergeMap(([packageId, newCount]) => asObservable(
        this.firestore()
          .collection("users")
          .doc(customerId)
          .collection("packages")
          .doc(packageId)
          .update({ leftTokenCount: newCount })
      )),
      map(() => { })
    )
}
