import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import strings from '../Utils/LocalizedStrings';

interface SimpleDialogProps {
  readonly isVisible: boolean;
  readonly title: string;
  readonly content?: string;
  readonly confirmButtonText?: string;
  readonly negativeButtonText?: string;
  readonly hideCancelButton?: boolean;
  readonly showNegativeButton?: boolean;
  readonly onConfirmButtonClicked: () => void;
  readonly onCancelButtonClicked?: () => void;
  readonly onNegativeButtonClicked?: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  negativeButton: {
    color: theme.palette.error.main
  }
}));

/**
 * A simple dialog that shows a title, an optional content, and provides 
 * a confirm and a cancel button.
 */
const SimpleDialog = (props: SimpleDialogProps) => {

  const classes = useStyles();

  return (
    <Dialog
      open={props.isVisible}
      onClose={() => {
        props.onCancelButtonClicked?.()
      }}
      PaperProps={{
        style: {
          borderRadius: "16px",
          padding: "4px"
        }
      }}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        {props.content ?? ""}
      </DialogContent>
      <DialogActions>
        {props.hideCancelButton
          ? null
          : <Button color="default" onClick={() => props.onCancelButtonClicked?.()}>
            {strings.commons.cancel}
          </Button>
        }
        {props.showNegativeButton
          ? <Button
            className={classes.negativeButton}
            color="default"
            variant="text"
            onClick={() => props.onNegativeButtonClicked?.()}>
            {props.negativeButtonText ?? ""}
          </Button>
          : <div />
        }
        <Button color="primary" onClick={props.onConfirmButtonClicked}>{props.confirmButtonText ?? "OK"}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SimpleDialog;