
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import GetAllCustomersUseCase from '../../../Domain/Customers/GetAllCustomersUseCase';
import GetRecentSearchedCustomerUseCase from '../../../Domain/Customers/GetRecentSearchedCustomerUseCase';
import SaveRecentSearchedCustomerUseCase from '../../../Domain/Customers/SaveRecentSearchedCustomerUseCase';
import SearchCustomersUseCase from '../../../Domain/Customers/SearchCustomersUseCase';
import UserInfo from '../../../Domain/UserInfo';
import Logger from '../../../Logger/Logger';
import { Failure, Loading, Success } from '../../../Utils/Result';
import CustomersView from './CustomersView';

export interface UiSimpleCustomer {
  readonly identifier: string,
  readonly displayName: string
}

export default class CustomersPresenter {

  private readonly searchRequestsSubject = new Subject<string>();
  private readonly subscription = new Subscription();
  private readonly allCustomers: Observable<UserInfo[]>;

  private view?: CustomersView;

  constructor(
    getAllCustomersUseCase: GetAllCustomersUseCase,
    private readonly searchCustomersUseCase: SearchCustomersUseCase,
    private readonly saveRecentSearchedCustomerUseCase: SaveRecentSearchedCustomerUseCase,
    private readonly getRecentSearchedCustomerUseCase: GetRecentSearchedCustomerUseCase
  ) {
    this.allCustomers = getAllCustomersUseCase.execute().pipe(shareReplay(1));
  }

  attachView = (v: CustomersView) => {
    this.view = v;
    this.subscription.add(
      this.allCustomers
        .pipe(
          map(customers =>
            customers.map(this.toSimpleCustomer)
              .sort((lhs, rhs) => lhs.displayName > rhs.displayName ? 1 : -1)
          ),
          switchMap(customers =>
            this.searchRequestsSubject
              .pipe(
                debounceTime(500),
                switchMap(query => {
                  if (query.trim().length === 0) {
                    return of(Success<UiSimpleCustomer[]>(customers));
                  } else {
                    return this.performSearch(query)
                      .pipe(
                        map(results => Success<UiSimpleCustomer[]>(results)),
                        catchError(error => of(Failure<UiSimpleCustomer[]>(error))),
                        startWith(Loading<UiSimpleCustomer[]>())
                      )
                  }
                }),
                startWith(Success<UiSimpleCustomer[]>(customers))
              )
          )
        )
        .subscribe(
          result => {
            if (result.isLoading) {
              this.view?.showSearchLoading();
            } else {
              this.view?.hideSearchLoading();
              result.fold(
                obj => this.onResults(obj),
                error => this.onError(error)
              );
            }
          },
          error => this.onError(error)
        )
    );
    this.subscription.add(
      this.getRecentSearchedCustomerUseCase.execute()
        .pipe(map(results => results.map(this.toSimpleCustomer)))
        .subscribe(
          recents => this.view?.showRecentSearchedCustomers(recents)
        )
    );
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  searchUsers = (query: string) => {
    this.searchRequestsSubject.next(query);
  }

  customerSelected = (customerId: string) => {
    this.subscription.add(
      this.saveRecentSearchedCustomerUseCase
        .execute(customerId)
        .subscribe(
          () => { },
          error => this.onError(error)
        )
    );
  }

  // Private methods

  private performSearch: (query: string) => Observable<UiSimpleCustomer[]> = (query) => {
    return this.searchCustomersUseCase.execute(query)
      .pipe(map(results => results.map(this.toSimpleCustomer)));
  }

  private toSimpleCustomer: (info: UserInfo) => UiSimpleCustomer = (info) => ({
    identifier: info.identifier,
    displayName: `${info.name} ${info.surname}`
  })

  private onResults = (results: UiSimpleCustomer[]) => {
    this.view?.showSearchResults(results);
  }

  private onError = (error: Error) => {
    Logger.e(error);
  }
};