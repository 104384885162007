import { useCallback, useEffect, useState } from 'react';
import LoadingDialog from '../../../Commons/LoadingDialog';
import SimpleDialog from '../../../Commons/SimpleDialog';
import Snackbar from '../../../Commons/Snackbar';
import strings from '../../../Utils/LocalizedStrings';
import CustomerDetailPresenter from './CustomerDetailPresenter';
import CustomerDetailScreen from './CustomerDetailScreen';
import CustomerDetailView from './CustomerDetailView';
import UiBasicAppointment from './UiBasicAppointment';
import UiCustomer from './UiCustomer';
import UiEditingCustomer from './UiEditingCustomer';

interface CustomerDetailViewControllerProps {
  readonly presenter: CustomerDetailPresenter;
  readonly onAssignPackageClicked: (customerId: string) => void;
  readonly onEditPackageClicked: (customerId: string, packageId: string) => void;
  readonly onCustomerDeleted: () => void;
}

interface CustomerDetailState {
  readonly presenter: CustomerDetailPresenter;
  readonly customer: UiCustomer | null;
  readonly editingCustomer: UiEditingCustomer | null;
  readonly appointments: UiBasicAppointment[] | null;
  readonly isLoadingAppointments: boolean;
  readonly isEditingEnabled: boolean;
  readonly isSavingEditedChanges: boolean;
  readonly isResetPasswordPopupVisible: boolean;
  readonly isDeleteCustomerPopupVisible: boolean;
  readonly isTemporaryLoadingVisible: boolean;
  readonly cancellationDialogInfo: CancellationDialogInfo | null;
  readonly errorMessage: string | null;
  readonly onCustomerDeleted: () => void;
}

interface CancellationDialogInfo {
  readonly appointmentId: string,
  readonly message: string,
}

const CustomerDetailViewController = (props: CustomerDetailViewControllerProps) => {
  const [state, setState] = useState<CustomerDetailState>({
    presenter: props.presenter,
    customer: null,
    editingCustomer: null,
    appointments: null,
    isLoadingAppointments: false,
    isEditingEnabled: false,
    isSavingEditedChanges: false,
    isResetPasswordPopupVisible: false,
    isDeleteCustomerPopupVisible: false,
    isTemporaryLoadingVisible: false,
    cancellationDialogInfo: null,
    errorMessage: null,
    onCustomerDeleted: props.onCustomerDeleted
  });
  const { presenter, onCustomerDeleted } = state;

  const view: () => CustomerDetailView = useCallback(() => ({
    showCustomer: (customer: UiCustomer) => {
      setState(state => ({ ...state, customer: customer }))
    },
    showAppointments: (appointments: UiBasicAppointment[]) => {
      setState(state => ({ ...state, appointments: appointments }))
    },
    showAppointmentsLoading: () => setState(state => ({ ...state, isLoadingAppointments: true })),
    hideAppointmentsLoading: () => setState(state => ({ ...state, isLoadingAppointments: false })),
    showResetPasswordLoading: () => setTemporaryLoadingVisible(true),
    hideResetPasswordLoading: () => setTemporaryLoadingVisible(false),
    showCustomerEditData: data => setState(state => ({ ...state, editingCustomer: data })),
    enableCustomerEditForm: () => setEditingEnabled(true),
    disableCustomerEditForm: () => setEditingEnabled(false),
    showCustomerEditLoading: () => setEditingLoading(true),
    hideCustomerEditLoading: () => setEditingLoading(false),
    showDeleteCustomerLoading: () => setTemporaryLoadingVisible(true),
    hideDeleteCustomerLoading: () => setTemporaryLoadingVisible(false),
    navigateToCustomersList: () => onCustomerDeleted(),
    showAppointmentCancellationDialog: (appointmentId, message) => setState(state => ({
      ...state,
      cancellationDialogInfo: {
        appointmentId: appointmentId,
        message: message
      }
    })),
    showError: message => setState(state => ({ ...state, errorMessage: message })),
  }), [onCustomerDeleted]);

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter, view]);

  // Temporary loading dialog

  const setTemporaryLoadingVisible = (visible: boolean) => setState(state => ({
    ...state,
    isTemporaryLoadingVisible: visible
  }))

  // Reset password

  const openResetPasswordDialog = () => setState(state => ({
    ...state,
    isResetPasswordPopupVisible: true
  }));

  const closeResetPasswordDialog = () => setState(state => ({
    ...state,
    isResetPasswordPopupVisible: false
  }));

  const resetPassword = () => {
    presenter.resetPassword();
    closeResetPasswordDialog();
  }

  // Edit customer

  const setEditingEnabled = (isEnabled: boolean) => setState(state => ({
    ...state,
    isEditingEnabled: isEnabled,
  }));

  const setEditingLoading = (isLoading: boolean) => setState(state => ({
    ...state,
    isSavingEditedChanges: isLoading
  }));

  // Delete customer

  const openDeleteCustomerDialog = () => setState(state => ({
    ...state,
    isDeleteCustomerPopupVisible: true
  }))

  const closeDeleteCustomerDialog = () => setState(state => ({
    ...state,
    isDeleteCustomerPopupVisible: false
  }))

  const deleteCustomer = () => {
    presenter.doDeleteCustomer();
    closeDeleteCustomerDialog();
  }

  // Cancel appointment

  const closeAppointmentCancellationDialog = () =>
    setState(state => ({ ...state, cancellationDialogInfo: null }));


  // Error message

  const clearErrorMessage = () => setState(state => ({
    ...state,
    errorMessage: null
  }));

  if (state.customer) {
    return (
      <div>
        {/* Main screen */}

        <CustomerDetailScreen
          customerName={state.editingCustomer?.name ?? state.customer.name}
          customerSurname={state.editingCustomer?.surname ?? state.customer.surname}
          customerEmail={state.editingCustomer?.email ?? state.customer.email}
          customerPhoneNumber={state.editingCustomer?.phoneNumber ?? state.customer.phoneNumber ?? ""}
          packages={state.customer.packages}
          appointments={state.appointments ?? []}
          isLoadingAppointments={state.isLoadingAppointments}
          onNameChanged={presenter.setCustomerName}
          onSurnameChanged={presenter.setCustomerSurname}
          onEmailChanged={presenter.setCustomerEmail}
          onPhoneNumberChanged={presenter.setCustomerPhoneNumber}
          isEditingEnabled={state.isEditingEnabled}
          isSavingEditedChanges={state.isSavingEditedChanges}
          onAssignPackageClicked={() => state.customer && props.onAssignPackageClicked(state.customer.identifier)}
          onResetPasswordClicked={openResetPasswordDialog}
          onEditCustomerClicked={presenter.editCustomerToggled}
          onCloseEditingCustomerClicked={presenter.closeCustomerEditing}
          onDeleteCustomerClicked={openDeleteCustomerDialog}
          onCancelAppointmentClicked={presenter.cancelAppointment}
          onEditPackageClicked={packageId =>
            state.customer
            && props.onEditPackageClicked(state.customer.identifier, packageId)
          }
          onDeletePackageClicked={presenter.deletePackage}
        />

        {/* Reset password */}

        <SimpleDialog
          isVisible={state.isResetPasswordPopupVisible}
          title={strings.admin.clients.detail.resetPasswordTitle}
          content={
            strings.formatString(
              strings.admin.clients.detail.resetPasswordConfirm,
              state.customer.name, state.customer.surname
            ).toString()
          }
          confirmButtonText={strings.admin.clients.detail.resetPassword}
          onConfirmButtonClicked={resetPassword}
          onCancelButtonClicked={closeResetPasswordDialog}
        />

        {/* Delete customer */}

        <SimpleDialog
          isVisible={state.isDeleteCustomerPopupVisible}
          title={strings.admin.clients.detail.deleteCustomerTitle}
          content={
            strings.formatString(
              strings.admin.clients.detail.deleteCustomerConfirm,
              state.customer.name,
              state.customer.surname
            ).toString()
          }
          confirmButtonText={strings.admin.clients.detail.deleteCustomer}
          onConfirmButtonClicked={deleteCustomer}
          onCancelButtonClicked={closeDeleteCustomerDialog}
        />

        {/* Cancel appointment */}

        <SimpleDialog
          isVisible={state.cancellationDialogInfo !== null}
          title={strings.admin.clients.detail.cancelAppointmentTitle}
          content={state.cancellationDialogInfo?.message ?? ""}
          confirmButtonText={strings.commons.confirm}
          onConfirmButtonClicked={() => {
            closeAppointmentCancellationDialog();
            presenter.confirmAppointmentCancellation(state.cancellationDialogInfo?.appointmentId);
          }}
          onCancelButtonClicked={closeAppointmentCancellationDialog} />

        {/* Temporary loading dialog */}

        {state.isTemporaryLoadingVisible ? <LoadingDialog /> : null}

        <Snackbar
          open={state.errorMessage !== null}
          message={state.errorMessage ?? ""}
          variant="error"
          onClosed={() => clearErrorMessage()}
        />
      </div>
    );
  } else {
    return <div />
  }
}

export default CustomerDetailViewController;

