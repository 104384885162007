import { Button, createStyles, makeStyles } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import AppFactory from '../../DI/AppFactory';
import logo from '../../logo.svg';
import { ROUTE_LOGIN, ROUTE_USER_BOOKING, ROUTE_USER_OVERVIEW, ROUTE_USER_PROFILE } from '../../Routing/RoutingConstants';
import Version from '../../version';
import LoadingDialog from '../Commons/LoadingDialog';
import ResponsiveDrawer from '../Commons/ResponsiveDrawer';
import SimpleDialog from '../Commons/SimpleDialog';
import LogoutPresenter from '../Logout/LogoutPresenter';
import LogoutView from '../Logout/LogoutView';
import strings from '../Utils/LocalizedStrings';
import UserBookingViewController from './Booking/UserBookingViewController';
import UserOverviewViewController from './Overview/UserOverviewViewController';
import UserProfileViewController from './Profile/UserProfileViewController';
import UserHomePresenter from './UserHomePresenter';
import UserHomeView from './UserHomeView';


const useStyles = makeStyles((theme) => createStyles({
  drawerContainerComponent: {
    background: theme.palette.common.white,
    padding: theme.spacing(3)
  },
  logoutButton: {
    color: theme.palette.common.white
  }
}));

const items = [
  {
    title: strings.user.navigation.overview,
    path: ROUTE_USER_OVERVIEW
  },
  {
    title: strings.user.navigation.booking,
    path: ROUTE_USER_BOOKING
  },
  {
    title: strings.user.navigation.profile,
    path: ROUTE_USER_PROFILE
  }
];

interface UserHomeViewControllerProps {
  readonly factory: AppFactory;
  readonly presenter: UserHomePresenter;
  readonly logoutPresenter: LogoutPresenter;

  /**
   * The path that the user wants to navigate to. 
   * Based on that, this component will render the matching subcomponent 
   * and react accordingly.
   */
  readonly selectedItemPath: string;
}

const UserHomeViewController = (props: UserHomeViewControllerProps) => {
  const { factory, presenter, logoutPresenter, selectedItemPath } = props;
  const [isLoading, setLoading] = useState(false);
  const [showBookingSuccessDialog, setShowBookingSuccessDialog] = useState(false);
  const history = useHistory();

  const view: () => UserHomeView = () => ({
    // view methods.
  });

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter]);

  const logoutView: () => LogoutView = useCallback(() => ({
    showLogoutProgress: () => setLoading(true),
    hideLogoutProgress: () => setLoading(false),
    navigateToLogin: () => history.replace(ROUTE_LOGIN)
  }), [history]);

  useEffect(() => {
    logoutPresenter.attachView(logoutView());
    return () => logoutPresenter.detachView();
  }, [logoutPresenter, logoutView]);

  const result = items.findIndex(item => selectedItemPath.indexOf(item.path) >= 0);
  const index = result >= 0 ? result : 0;

  const classes = useStyles();

  return (
    <ResponsiveDrawer
      key={index}
      logo={logo}
      drawerListItems={items}
      footer={Version.name}
      rightButtonItem={() =>
        <Button
          className={classes.logoutButton}
          startIcon={<ExitToApp />}
          onClick={() => logoutPresenter.logout()}>
          {strings.commons.logout}
        </Button>
      }
      selectedIndex={index}>
      <div className={classes.drawerContainerComponent}>
        <Switch>
          <Route
            path={ROUTE_USER_OVERVIEW}
            render={() =>
              <UserOverviewViewController
                presenter={factory.userOverviewPresenter()}
                onBookNowClicked={() => history.push(ROUTE_USER_BOOKING)}
              />
            } />
          <Route
            path={ROUTE_USER_BOOKING}
            render={() =>
              <UserBookingViewController
                presenterFactory={factory.userBookingPresenter}
                onBookingCompleted={() => {
                  setShowBookingSuccessDialog(true);
                }}
                onBookingFailed={() => {
                }}
              />
            } />
          <Route
            path={ROUTE_USER_PROFILE}
            render={() =>
              <UserProfileViewController
                presenter={factory.userProfilePresenter()}
              />
            } />
          <Redirect to={ROUTE_USER_OVERVIEW} />
        </Switch>
        {isLoading ? <LoadingDialog /> : <div />}
      </div>
      <SimpleDialog
        isVisible={showBookingSuccessDialog}
        onConfirmButtonClicked={() => setShowBookingSuccessDialog(false)}
        title={strings.user.booking.bookingSuccess}
        content={strings.user.booking.bookingSuccessMessage}
        hideCancelButton />
    </ResponsiveDrawer>
  )
}

export default UserHomeViewController;

