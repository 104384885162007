import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  it: {
    login: {
      loginButton: "Accedi",
      email: "E-mail",
      password: "Password",
      invalidEmail: "Indirizzo non valido",
      invalidPassword: "Password non valida",
    },
    admin: {
      navigation: {
        appointments: "Appuntamenti",
        clients: "Clienti",
        companyClosures: "Chiusure aziendali",
        settings: "Impostazioni"
      },
      calendar: {
        today: "Oggi",
        day: "Giorno",
        week: "Settimana",
        month: "Mese",
        oneClient: "1 cliente",
        moreClient: "{0} clienti",
      },
      clients: {
        searchHint: "Cerca per nome e cognome...",
        noSearchMadeYet: "Ancora nessuna ricerca effettuata, digita del testo per iniziare!",
        searchResultsPlaceholder: "I risultati di ricerca verranno mostrati qui.",
        recents: "Recenti",
        clients: "Clienti",
        newClient: "Nuovo cliente",
        detail: {
          title: "Dettaglio cliente",
          name: "Nome",
          surname: "Cognome",
          email: "E-mail",
          phone: "Telefono",
          packages: "Pacchetti",
          tokensPerWeek: "gettoni a settimana",
          noAssignedPackages: "Il cliente non ha pacchetti assegnati.",
          appointments: "Appuntamenti",
          noAppointments: "Non ci sono appuntamenti in programma.",
          manage: "Gestisci",
          assignPackage: "Assegna pacchetto",
          resetPassword: "Reset password",
          deleteCustomer: "Elimina cliente",
          errorGettingClientInfo: "Si è verificato un errore nel recuperare le informazioni del cliente. Riprova più tardi.",
          errorGettingAppointments: "Si è verificato un errore nel recuperare gli appuntamenti dell'utente. Riprova più tardi.",
          errorEditingClient: "Oops, si è verificato un errore durante la modifica dei dati cliente!\nVerifica di aver inserito tutti i campi correttamente.",
          resetPasswordTitle: "Reset password",
          resetPasswordConfirm: "Vuoi resettare la password di {0} {1}?\nUna e-mail di avvenuto reset verrà inviata al cliente, dove troverà anche la nuova password.",
          deleteCustomerTitle: "Elimina cliente",
          deleteCustomerConfirm: "ATTENZIONE: Azione distruttiva!\nSei sicuro di voler rimuovere {0} {1} dal sistema?\nTutti i dati memorizzati andranno persi. Vuoi continuare?",
          cancelAppointmentTitle: "Annulla appuntamento",
          cancelAppointmentConfirm: "Sei sicuro di voler cancellare l'appuntamento di {0} alle {1}?",
          deletePackageTitle: "Elimina pacchetto",
          deletePackageMessage: "Vuoi veramente eliminare il seguente pacchetto? Attenzione, gli appuntamenti già programmati rimarranno validi.",
          deletePackageAction: "Elimina pacchetto"
        },
        creation: {
          title: "Nuovo cliente",
          mainSection: "Generale",
          confirmationTitle: "Conferma aggiunta cliente",
          addClient: "Aggiungi cliente",
          errorTitle: "Errore aggiunta cliente",
          errorMessage: "Si è verificato un errore creando il nuovo utente. Controlla la connessione e i dati che hai inserito. Se l'errore persiste, ricarica la pagina."
        },
        packageAssignment: {
          title: "Assegna pacchetto",
          assignTo: "Assegna pacchetto a {0}",
          packageToAssign: "Pacchetto da assegnare",
          confirmAssignment: "Conferma assegnazione pacchetto",
          assignPackage: "Assegna pacchetto",
          buyDate: "Data acquisto pacchetto",
          startDate: "Data inizio pacchetto",
          endDate: "Data fine pacchetto",
          tokensLimit: "Limite ingressi a settimana",
          leftTokens: "Gettoni residui",
          client: "Cliente",
          package: "Pacchetto",
          buyDateShort: "Data acquisto",
          startDateShort: "Data inizio",
          endDateShort: "Data scadenza",
          tokensLimitShort: "Entrate sett.",
          errorMissingType: "Seleziona un pacchetto da assegnare",
          errorMissingBuyDate: "Seleziona una data di acquisto",
          errorMissingStartDate: "Seleziona una data di inizio",
          errorMissingEndDate: "Seleziona una data di scadenza",
          errorFutureEndDate: "La data di scadenza deve essere successiva a quella di inizio",
        }
      },
      companyClosures: {
        formatAllDay: "{0}, tutto il giorno.",
        formatFromTo: "Da {0} a {1}.",
        formatHoursFromTo: "{0}, dalle {1} alle {2}.",
        closed: "Chiuso",
        detailTitle: "Chiusura aziendale"
      },
      settings: {
        businessHours: "Giorni lavorativi",
        businessHoursTopDescr: "Imposta qui le fasce di orario lavorativo quotidiane.",
        businessHoursBottomDescr: "Saranno utilizzate assieme ai giorni di chiusura aziendale per determinare i giorni prenotabili.",
        seasonalHours: "Orari stagionali",
        addHours: "Aggiungi un orario",
        newHours: "Nuovo orario",
        from: "Dalle",
        to: "alle",
        customersLimit: "max. clienti",
        detailsSectionTitle: "Dettagli",
        description: "Descrizione",
        descriptionSample: "i.e. Orario Estivo",
        startDate: "Data inizio validità",
        endDate: "Data fine validità",
        errorFutureEndDate: "La data di fine è antecedente alla data di inizio",
        saveTooltip: "Salva i dati del box \"Dettagli\"",
        saveDetails: "Salva dettagli",
        deleteSeasonalHoursTooltip: "Elimina l'intero orario",
        deleteSeasonalHours: "Elimina orario",
        appointmentDuration: "Durata appuntamenti",
        appointmentDurationTopDescr: "Imposta qui la durata di un appuntamento.",
        appointmentDurationBottomDescr: "Verrà utilizzata per mostrare gli slot temporali nei quali un cliente può prenotare.",
        account: "Account",
        changePassword: "Cambia password",
      }
    },
    user: {
      navigation: {
        overview: "Riepilogo",
        booking: "Prenotazione",
        profile: "Profilo",
      },
      overview: {
        welcome: "PerformHealth ti dà il benvenuto",
        packages: "I tuoi pacchetti",
        noPackages: "Non hai pacchetti attivi. Vieni a trovarci per iniziare!",
        availableTokensPerWeek: "Questa settimana hai a disposizione ancora",
        availableTokensPerPackage: "Hai a disposizione ancora",
        token: "gettone",
        tokens: "gettoni",
        bookings: "Le tue prenotazioni",
        bookingsMessage: "Ecco gli appuntamenti che hai prenotato:",
        noBookings: "Non hai prenotazioni future.",
        book: "Prenota adesso",
      },
      booking: {
        descriptionTop: "Qui puoi effettuare una nuova prenotazione.",
        descriptionBottom: "Seleziona una data disponibile, e poi un orario tra quelli elencati.",
        selectDate: "Seleziona una data",
        selectTimeSlot: "Seleziona un orario",
        book: "Prenota",
        confirmationTitle: "Confermi la prenotazione?",
        date: "Data",
        timeSlot: "Orario",
        errorNoMoreBookings: "Non è possibile effettuare ulteriori prenotazioni durante la settimana corrente. Controlla di avere un pacchetto attivo o di non aver esaurito il numero di gettoni.",
        bookingSuccess: "Prenotazione effettuata",
        bookingSuccessMessage: "La tua prenotazione è andata a buon fine! Puoi rivedere la tua prenotazione nella schermata principale.",
        bookingFailed: "Errore durante la prenotazione",
        bookingFailedGenericMessage: "Si è verificato un errore durante la prenotazione, per favore riprova.",
        bookingFailedTimeSlotsUnavailableMessage: "Siamo spiacenti, i posti disponibili per questo orario sono stati occupati mentre effettuavi la prenotazione. Per favore, seleziona un altro orario.",
        availableDaysLoading: "Caricamento disponibilità mensili...",
      },
      profile: {
        title: "Il tuo profilo",
        subtitle: "Modifica liberamente le tue informazioni. Premi il pulsante \"Salva\" quando hai finito.",
        notifyVariation: "Per aggiornare la tua email o segnalare una variazione,",
        clickHere: "clicca qui",
        phoneNumber: "Telefono",
        cancelModifications: "Annulla modifiche",
        errorInvalidPhoneNumber: "Il numero inserito non è valido"
      },
    },
    packages: {
      threeMonthsPackage: "Pacchetto 3 mesi",
      sixMonthsPackage: "Pacchetto 6 mesi",
      twelveMonthsPackage: "Pacchetto 12 mesi",
      perUsagePackage: "Pacchetto a consumo",
      statusActive: "Attivo",
      statusInactive: "Inattivo",
      statusExpired: "Scaduto",
    },
    error: {
      title: "Ooops!",
      networkUnreachable: "Qualcosa è andato storto. La tua connessione sembra avere dei problemi, per favore controlla e ricarica la pagina.",
      genericError: "Qualcosa è andato storto, per favore ricarica la pagina.",
    },
    commons: {
      close: "Chiudi",
      edit: "Modifica",
      cancel: "Annulla",
      confirm: "Conferma",
      save: "Salva",
      delete: "Elimina",
      from: "dal",
      to: "al",
      optional: "opzionale",
      error: "Errore",
      logout: "Esci",
      other: "Altro",
    },
    passwordChange: {
      description: "Se vuoi, puoi aggiornare la tua password.",
      newPassword: "Nuova password",
      confirmPassword: "Conferma password",
      errorPasswordMismatch: "Le password non corrispondono",
      errorPasswordLength: "La password deve essere lunga almeno {0} caratteri",
    },
    alt: {
      token: "Icona del gettone",
      package: "Immagine del pacchetto"
    }
  },
  en: {
    login: {
      loginButton: "Login",
      email: "E-mail",
      password: "Password",
      invalidEmail: "Invalid e-mail address",
      invalidPassword: "Invalid password"
    },
    admin: {
      navigation: {
        appointments: "Appointments",
        clients: "Clients",
        companyClosures: "Company closures",
        settings: "Settings"
      },
      calendar: {
        today: "Today",
        day: "Day",
        week: "Week",
        month: "Month",
        oneClient: "1 client",
        moreClient: "{0} clients",
      },
      clients: {
        searchHint: "Search by name and surname...",
        noSearchMadeYet: "No search made yet, start by typing some text!",
        searchResultsPlaceholder: "Search results will appear here.",
        recents: "Recents",
        clients: "Customers",
        newClient: "New client",
        detail: {
          title: "Client info",
          name: "Name",
          surname: "Surname",
          email: "E-mail",
          phone: "Phone number",
          packages: "Packages",
          tokensPerWeek: "tokens per week",
          noAssignedPackages: "Client doesn't have any packages yet.",
          appointments: "Appointments",
          noAppointments: "There aren't scheduled appointments.",
          manage: "Manage",
          assignPackage: "Assign package",
          resetPassword: "Reset password",
          deleteCustomer: "Delete client",
          errorGettingClientInfo: "An error occurred while getting client's data. Please try again later.",
          errorGettingAppointments: "An error occurred while getting client's appointments. Please try again later.",
          errorEditingClient: "Oops, an error occurred while editing client's data.\nCheck the inserted data and try again.",
          resetPasswordTitle: "Reset password",
          resetPasswordConfirm: "Do you want to reset {0} {1}'s password?\nA notification e-mail will be sent to the customer, which will contain the new password.",
          deleteCustomerTitle: "Delete client",
          deleteCustomerConfirm: "WARNING: Terminal action!\nAre you sure you want to remove {0} {1} from the system?\nAll the related data will be lost. Do you want to proceed?",
          cancelAppointmentTitle: "Cancel appointment",
          cancelAppointmentConfirm: "Are you sure you want to cancel the appointment that takes place on {0} at {1}?",
          deletePackageTitle: "Delete package",
          deletePackageMessage: "Are you sure you want to delete this package? Please remember that the already scheduled appointments will still be valid.",
          deletePackageAction: "Delete package"
        },
        creation: {
          title: "New client",
          mainSection: "General",
          confirmationTitle: "Confirm client creation",
          addClient: "Add client",
          errorTitle: "Error adding client",
          errorMessage: "An error occurred creating the new customer. Check your connection and the data you provided. If the error persists, please reload the page."
        },
        packageAssignment: {
          title: "Assign package",
          assignTo: "Assign package to {0}",
          packageToAssign: "Package to assign",
          confirmAssignment: "Confirm package assignment",
          assignPackage: "Assign package",
          buyDate: "Package buy date",
          startDate: "Package start date",
          endDate: "Package expiration date",
          tokensLimit: "Weekly bookings limit",
          leftTokens: "Tokens left",
          client: "Client",
          package: "Package",
          buyDateShort: "Buy date",
          startDateShort: "Start date",
          endDateShort: "Expiration date",
          tokensLimitShort: "Weekly limit",
          errorMissingType: "Select a package to assign",
          errorMissingBuyDate: "Select the buy date",
          errorMissingStartDate: "Select the start date",
          errorMissingEndDate: "Select an expiration date",
          errorFutureEndDate: "Expiration date must be greater than the start date"
        }
      },
      companyClosures: {
        formatAllDay: "{0} all day.",
        formatFromTo: "From {0} to {1}.",
        formatHoursFromTo: "{0}, from {1} to {2}.",
        closed: "Closed",
        detailTitle: "Company closure"
      },
      settings: {
        businessHours: "Business days",
        businessHoursTopDescr: "Setup here the daily business hours.",
        businessHoursBottomDescr: "They will be used together with the company closured for determining the days in which clients can book.",
        seasonalHours: "Seasonal business days",
        addHours: "Add new seasonal business days",
        newHours: "New business hours",
        from: "From",
        to: "to",
        customersLimit: "clients limit",
        detailsSectionTitle: "Details",
        description: "Description",
        descriptionSample: "i.e. Summer business days",
        startDate: "Start date",
        endDate: "End date",
        errorFutureEndDate: "End date must be greater than the start date",
        saveTooltip: "Save data of \"Details\" box",
        saveDetails: "Save details",
        deleteSeasonalHoursTooltip: "Delete the whole seasonal hours",
        deleteSeasonalHours: "Delete hours",
        appointmentDuration: "Appointments duration",
        appointmentDurationTopDescr: "Setup here the duration of an appointment.",
        appointmentDurationBottomDescr: "It will be used for showing the available time slots in which a client can book.",
        account: "Account",
        changePassword: "Change password",
      }
    },
    user: {
      navigation: {
        overview: "Overview",
        booking: "Booking",
        profile: "Profile",
      },
      overview: {
        welcome: "Welcome to PerformHealth",
        packages: "Your packages",
        noPackages: "You don't have active packages yet.",
        availableTokensPerWeek: "This week you can use up to",
        availableTokensPerPackage: "You can still use up to",
        token: "token",
        tokens: "tokens",
        bookings: "Your bookings",
        bookingsMessage: "Here are the appointments you booked:",
        noBookings: "There aren't scheduled bookings.",
        book: "Book now",
      },
      booking: {
        descriptionTop: "Here you can book an appointment.",
        descriptionBottom: "Choose an available date, and then select a time slot from the dropdown.",
        selectDate: "Choose a date",
        selectTimeSlot: "Choose a time slot",
        book: "Book",
        confirmationTitle: "Do you want to confirm the appointment?",
        date: "Date",
        timeSlot: "Time slot",
        errorNoMoreBookings: "Cannot book other appointments during this week. Please ensure to have an active subscription or to have enough tokens to proceed with a reservation.",
        bookingSuccess: "Booking completed",
        bookingSuccessMessage: "You just booked an appointment! You can review it in the main page.",
        bookingFailed: "Booking error",
        bookingFailedGenericMessage: "An error occurred while booking the appointment, please retry.",
        bookingFailedTimeSlotsUnavailableMessage: "We are sorry, the available seats have been filled while you were booking your appointment. Please retry with another time slot, thanks.",
        availableDaysLoading: "Loading month availabilities...",
      },
      profile: {
        title: "Your profile",
        subtitle: "Edit your data as you want. Click on \"Save\" when you're done.",
        notifyVariation: "For updating your e-mail address or to notifying any variation,",
        clickHere: "click here",
        phoneNumber: "Phone number",
        cancelModifications: "Cancel",
        errorInvalidPhoneNumber: "Provided phone number is invalid"
      },
    },
    packages: {
      threeMonthsPackage: "3 months package",
      sixMonthsPackage: "6 months package",
      twelveMonthsPackage: "12 months package",
      perUsagePackage: "Usage package",
      statusActive: "Active",
      statusInactive: "Inactive",
      statusExpired: "Expired",
    },
    error: {
      title: "Ooops!",
      networkUnreachable: "Something went wrong. You network seems unstable, please check it and refresh the page.",
      genericError: "Something went wrong, please refresh the page."
    },
    commons: {
      close: "Close",
      edit: "Edit",
      cancel: "Cancel",
      save: "Save",
      delete: "Delete",
      from: "from",
      to: "to",
      optional: "optional",
      error: "Error",
      logout: "Logout",
      other: "Other"
    },
    passwordChange: {
      description: "You can change your password if you want to.",
      newPassword: "New password",
      confirmPassword: "Confirm password",
      errorPasswordMismatch: "Passwords do not match",
      errorPasswordLength: "Password must be at least {0} characters long",
    },
    alt: {
      token: "Token icon",
      package: "Package image",
      errorImageAlt: "Generic image that represents an error",
    }
  }
});

export default strings;