import DateFnsUtils from '@date-io/date-fns';
import { Box, createStyles, FormControl, FormHelperText, Grid, makeStyles, TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PackageType } from '../../../../Domain/Entities/PackageTypes';
import Select from '../../../Commons/Select';
import strings from '../../../Utils/LocalizedStrings';
import PackageAssignmentErrors from './PackageAssignmentErrors';
import PackageAssignmentInfo from './PackageAssignmentInfo';

interface PackageAssignmentViewProps {
  readonly title: string;
  readonly packageSelectLabel: string;
  readonly info: PackageAssignmentInfo | null;
  readonly errors: PackageAssignmentErrors;
  readonly packages: [PackageType, string][];
  readonly onPackageTypeChange: (type: PackageType | null) => void;
  readonly onBuyDateChange: (buyDate: Date | null) => void;
  readonly onStartDateChange: (startDate: Date | null) => void;
  readonly onEndDateChange: (endDate: Date | null) => void;
  readonly onTokenLimitChange: (tokenLimit: any | null) => void;
  readonly onLeftTokenCountChange: (tokenCount: any | null) => void;
  readonly showBuyDate?: boolean;
  readonly showLeftTokenCount?: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  gridItem: {
    display: "flex",
    flexGrow: 1,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "column"
  },
  sectionTitle: {
    ...theme.typography.h6,
    display: "flex",
    flexGrow: 1,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
}));

const PackageAssignmentScreen = (props: PackageAssignmentViewProps) => {

  const handlePackageSelect = (packageId: string) => {
    props.onPackageTypeChange((packageId as PackageType) ?? null);
  }

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={1} md={2} lg={3} />
      <Grid item xs={12} md={8} lg={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box className={classes.fieldsContainer}>

            <span className={classes.sectionTitle}>{props.title}</span>

            {/* Packages */}
            <Select
              className={classes.gridItem}
              label={props.packageSelectLabel}
              enabled
              entries={props.packages.map(([key, value]) => ({ key: key, value: value }))}
              value={props.info?.packageType ?? undefined}
              errorMessage={props.errors.packageTypeError}
              onSelect={handlePackageSelect} />

            {/* 
              Use `null` as default value of date fields otherwise MaterialUI 
              will pick today's date as default value.
            */}

            {/* Package buy date */}
            {props.showBuyDate
              ? <FormControl
                className={classes.gridItem}
                error={!(!props.errors.packageStartDateError)}>
                <DatePicker
                  label={strings.admin.clients.packageAssignment.buyDate}
                  value={props.info?.packageBuyDate ?? null}
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  onChange={date => props.onBuyDateChange(date ?? null)} />
                <FormHelperText>{props.errors.packageBuyDateError ?? ''}</FormHelperText>
              </FormControl>
              : null
            }

            {/* Package start date */}
            <FormControl
              className={classes.gridItem}
              error={!(!props.errors.packageStartDateError)}>
              <DatePicker
                label={strings.admin.clients.packageAssignment.startDate}
                value={props.info?.packageStartDate?.current ?? null}
                minDate={props.info?.packageStartDate?.min ?? undefined}
                maxDate={props.info?.packageStartDate?.max ?? undefined}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                onChange={date => props.onStartDateChange(date ?? null)} />
              <FormHelperText>{props.errors.packageStartDateError ?? ''}</FormHelperText>
            </FormControl>

            {/* Package end date */}

            {props.info?.packageEndDate
              ? <FormControl
                className={classes.gridItem}
                error={!(!props.errors.packageEndDateError)}>
                <DatePicker
                  label={strings.admin.clients.packageAssignment.endDate}
                  value={props.info?.packageEndDate?.current ?? null}
                  minDate={props.info?.packageEndDate?.min ?? undefined}
                  maxDate={props.info?.packageEndDate?.max ?? undefined}
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  onChange={date => props.onEndDateChange(date ?? null)} />
                <FormHelperText>{props.errors.packageEndDateError ?? ''}</FormHelperText>
              </FormControl>
              : null
            }

            {/* Weekly limit */}
            <FormControl
              className={classes.gridItem}
              error={!(!props.errors.packageStartDateError)}>
              <TextField
                inputProps={{ type: "number", min: 1, max: 4 }}
                label={strings.admin.clients.packageAssignment.tokensLimit}
                variant="outlined"
                value={props.info?.weeklyTokensLimit ?? ""}
                onChange={event => props.onTokenLimitChange(event?.target?.value)} />
            </FormControl>

            {/* Left token count */}
            {props.showLeftTokenCount
              ? <FormControl
                className={classes.gridItem}
                error={!(!props.errors.packageStartDateError)}>
                <TextField
                  type="number"
                  label={strings.admin.clients.packageAssignment.leftTokens}
                  variant="outlined"
                  value={props.info?.leftTokenCount ?? ""}
                  onChange={event => props.onLeftTokenCountChange(event?.target?.value)} />
                <FormHelperText>{props.errors.packageStartDateError ?? ""}</FormHelperText>
              </FormControl>
              : null
            }
          </Box>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={1} md={2} lg={3} />
    </Grid>

  )
};

export default PackageAssignmentScreen;

