import { CircularProgress, Dialog, DialogContent, makeStyles, createStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => createStyles({
  dialog: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

/**
 * A dialog which shows an indeterminate circular progress bar.
 */
const LoadingDialog = () => {
  const classes = useStyles();
  return (
    <Dialog open 
      PaperProps={{
        style: {
          borderRadius: "16px",
          padding: "4px"
        }
      }}
    >
      <DialogContent>
        <CircularProgress className={classes.dialog}/>
      </DialogContent>
    </Dialog>
  );
}

export default LoadingDialog;