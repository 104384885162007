import { dateTruncatingTime } from "../../Utils/DateUtils";
import { PackageType } from "./PackageTypes";

export default interface Package {
  readonly identifier: string;
  readonly type: PackageType;
  readonly buyDate: Date;
  readonly initialTokenCount: number;
  readonly leftTokenCount: number;
  readonly maxWeeklyBookingsCount: number;
  readonly startDate: Date;
  readonly expirationDate: Date | null;
  readonly updateDate: Date;
}

export type PackageState = "active" | "inactive" | "expired";

export const packageStatusActive = "active";
export const packageStatusInactive = "inactive";
export const packageStatusExpired = "expired";

export const getPackageState = (pack: Package, currentDate: Date): PackageState => {
  switch (true) {
    case pack.expirationDate && dateTruncatingTime(pack.expirationDate) < dateTruncatingTime(currentDate):
      return packageStatusExpired;
    case pack.startDate.getTime() > currentDate.getTime():
      return packageStatusInactive;
    default:
      return packageStatusActive;
  }
}