import { from, Observable } from "rxjs";
import { map, mergeMap, switchMap, take, toArray } from "rxjs/operators";
import UserInfo from "../UserInfo";
import CustomersRepository from "./CustomersRepository";

/**
 * Returns the info of the latest searched users.
 */
export default class GetRecentSearchedCustomerUseCase {

  private readonly customersRepository: CustomersRepository;

  constructor(
    customersRepository: CustomersRepository
  ) {
    this.customersRepository = customersRepository;
  }

  execute: () => Observable<UserInfo[]> = () => this.customersRepository
    .getRecentSearchedCustomers()
    .pipe(
      switchMap(ids => 
        from(ids)
        .pipe(
          take(4), // Only get the first 4 customers
          mergeMap(id => this.customersRepository.getCustomerInfo(id).pipe(take(1))),
          toArray()
        )
      ),
      map(customers =>
        customers
          .filter(info => info !== null)
          .map(info => info as UserInfo)
      )
    )
}