import { Subscription } from "rxjs";
import { mapTo, startWith } from "rxjs/operators";
import LoginUseCase from "../../Domain/LoginUseCase";
import UserErrors from "../../Domain/UserErrors";
import { Constants } from "../../Utils/Constants";
import AdminLoginView from "./AdminLoginView";

interface LoginForm {
  readonly email: string;
  readonly password: string;
}

export default class AdminLoginPresenter {

  private readonly subscription = new Subscription();

  private loginForm: LoginForm = {
    email: "",
    password: ""
  };

  private view?: AdminLoginView;

  constructor(
    private readonly loginUseCase: LoginUseCase
  ) {
  }

  attachView = (v: AdminLoginView) => {
    this.view = v;
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  setEmail = (email: string) => {
    this.loginForm = {
      ...this.loginForm,
      email: email
    };
  }

  setPassword = (password: string) => {
    this.loginForm = {
      ...this.loginForm,
      password: password
    };
  }

  login = () => {

    const { email, password } = this.loginForm;

    if (this.validateEmail(email) && password.length > 0) {
      this.view && this.view.hideEmailError();
      this.view && this.view.hidePasswordError();
      this.performLogin(email, password);
    } else {
      if (email.length === 0) {
        this.view && this.view.showEmailError();
      } else {
        this.view && this.view.hideEmailError();
      }
      if (password.length === 0) {
        this.view && this.view.showPasswordError();
      } else {
        this.view && this.view.hidePasswordError();
      }
    }
  }

  // Private methods

  private performLogin(email: string, password: string) {
    this.subscription.add(
      this.loginUseCase
        .execute(email, password)
        .pipe(mapTo(false), startWith(true))
        .subscribe(
          isLoading => {
            if (isLoading) {
              this.view?.showLoading();
            } else {
              this.view?.hideLoading();
              this.view?.navigateToAdminHome();
            }
          },
          error => this.handleError(error)
        )
    );
  }

  private handleError(error: any) {
    this.view?.hideLoading();

    switch (error.message) {
      case UserErrors.wrongEmail:
        this.view?.showEmailError();
        break;
      case UserErrors.wrongPassword:
        this.view?.showPasswordError();
        break;
      case UserErrors.tooManyFailedLoginAttempts:
        // TODO
        break;
    }
  }

  private validateEmail(email: string): boolean {
    return email.match(Constants.emailRegex) != null;
  }
};